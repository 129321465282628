import React, { useState } from 'react';
// import { Send } from 'lucide-react';

const LandingPage = ({title, description, avatarUrl}) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://workflows.smarktive.com.br/webhook/20fc2c38-9c70-4f80-a6ba-898989653085', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Falha ao enviar o e-mail. Por favor, tente novamente.');
      }

      const data = await response.json();
      console.log('Resposta da API:', data);
      
      setIsSubmitted(true);
      setEmail('');
    } catch (err) {
      console.error('Erro:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-between p-4">
      {/* Main Content Section */}
      <div className="flex-1 flex flex-col items-center max-w-2xl w-full space-y-8 py-12">
        {/* Avatar */}
        <div className="w-[175px] h-[175px] rounded-full overflow-hidden">
          <img
            src={avatarUrl}
            alt="Avatar"
            className="w-full h-full object-contain"
          />
        </div>

        {/* Title */}
        <h1 className="text-4xl md:text-5xl font-bold text-center text-gray-900 px-4">
          {isSubmitted ? "Inscrição concluída com sucesso!" : title}
        </h1>

        {/* Optional Description */}
        <p className="text-lg text-gray-600 text-center px-4">
          {isSubmitted 
            ? "Você receberá todos os dias pela manhã a edição diária da nossa newsletter."
            : description
          }
        </p>

        {/* Email Form */}
        {!isSubmitted && (
          <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4 px-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Seu melhor email"
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              disabled={isLoading}
            />
            <button 
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-200"
              disabled={isLoading}
            >
              {isLoading ? 'Enviando...' : 'Inscrever-se (Grátis)'}
            </button>
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </form>
        )}
      </div>

      {/* Telegram Link */}
      {/* <div className="pb-8">
        <a
          href="#"
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="w-6 h-6 fill-current"
          >
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.535.223l.19-2.712 4.94-4.465c.215-.19-.047-.297-.332-.107l-6.107 3.843-2.625-.816c-.57-.182-.582-.57.12-.842l10.273-3.96c.474-.176.89.106.576 1.664z"/>
          </svg>
          <span>Acompanhe também pelo Telegram</span>
        </a>
      </div> */}
    </div>
  );
};

export default LandingPage;