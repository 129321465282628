import React from 'react';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <LandingPage 
      title="Receba notícias da cidade de Três Rios (RJ)."
      description="Junte-se aos 3.578 leitores trirrienses ativos."
      avatarUrl="/assets/img/brasao-tres-rios-rj.png"
      onSubmit={(email) => console.log('Email enviado:', email)}
    />
  );
}

export default App;